import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useChangeList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refCustomerProfileListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'ID',
      sortable: true,
    },
    {
      key: 'customer.name',
      label: 'Customer',
      sortable: true,
    },
    {
      key: 'change',
      label: 'Requested change',
      sortable: false,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalChanges = ref(0)
  const searchQuery = ref(route.query.search || '')
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref(route.query.sortBy ?? 'updatedAt')
  const isSortDirDesc = ref(route.query.sortDesc !== 'false')
  const statusFilter = ref([])
  const requestData = ref([])
  if (Array.isArray(route.query.status)) {
    statusFilter.value = route.query.status
  } else if (route.query.status) {
    statusFilter.value = [route.query.status]
  }
  const toDeletedID = ref(null)
  let initialLoad = true
  const currentDataLength = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerProfileListTable.value ? refCustomerProfileListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalChanges.value,
    }
  })

  const refetchData = () => {
    refCustomerProfileListTable.value.refresh()
  }

  let timer = null
  watch([searchQuery, statusFilter], () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      currentPage.value = 1
      refetchData()
    }, 1000)
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchCustomerProfileLists = (ctx, callback) => {
    store
      .dispatch('app-profile-change-list/fetchCustomerProfileLists', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      },
      { root: true })
      .then(response => {
        const { list, total } = response.data
        currentDataLength.value = list.length
        requestData.value = list
        callback(list)
        totalChanges.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveLogStatusVariant = status => {
    if (status === 'approved') return 'success'
    if (status === 'awaiting approval') return 'warning'
    if (status === 'rejected') return 'danger'
    return 'primary'
  }

  return {
    fetchCustomerProfileLists,
    tableColumns,
    perPage,
    currentPage,
    totalChanges,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refCustomerProfileListTable,
    requestData,

    searchQuery,
    statusFilter,

    resolveLogStatusVariant,
    refetchData,
    currentDataLength,

    toDeletedID,
  }
}
