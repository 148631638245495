<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group label="Status">
      <b-form-checkbox
        v-for="option in statusOptions"
        :key="option.value"
        v-model="statusFilterValue"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.label }}
      </b-form-checkbox>
    </b-form-group>
    <div class="d-flex justify-content-between gap-3 mt-2">
      <b-button
        variant="outline-primary"
        class="mr-1 w-50"
        @click="clearFilter"
      >
        Clear All
      </b-button>
      <b-button
        variant="primary"
        class="w-50"
        @click="applyFilter"
      >
        Apply Filter
      </b-button>
    </div>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
  },
  props: {
    statusFilter: {
      type: [Array, null],
      default: () => [],
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusFilterValue: JSON.parse(JSON.stringify(this.statusFilter)) || [],
    }
  },
  watch: {
    statusFilter: {
      immediate: true,
      handler(val) {
        this.statusFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.statusFilterValue = []
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
